<template>
  <div style="background-color:#fff; height:auto; overflow-y:auto;">
    <div style="overflow-y:auto; padding-top:12.5%">
      <img style="width:100%; float:left;" src="@/assets/about_blue.png" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'AboutScene',
}
</script>

<style scoped>

</style>