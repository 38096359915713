<template>
  <div style="display:flex; flex-direction: column; justify-content:space-between; height:100vh; overflow:hidden;">

    <div style="height:70px; display:flex; justify-content:space-between;">
        <!--<img src="@/assets/ui/UI_back arrow.png" @click="$emit('go-to-menu')" />
        <div style="display:flex; justify-content:flex-end; height:70px;">
          <img @click="volumeDown" src="@/assets/ui/UI_vol01 volume down.png" />
          <img src="@/assets/ui/UI_vol02 headphone icon.png" />
          <img @click="volumeUp" src="@/assets/ui/UI_vol03 volume up.png" />
        </div>-->
    </div>

    <!--<div style="position:relative;">
      <video style="max-width:100%; max-height:calc(100vh - 140px); object-fit: contain;"
          :src="video"
          ref="vid"
          type="video/mp4"
          @click="togglePlayback"
          @ended="videoended" />

      <img v-if="!playing" style="opacity:0.5; width: 200px; position:absolute; left:50%; top:50%; transform: translate(-50%, -50%);" src="@/assets/ui/UI_video PLAY button.png" @click="play" />
    </div>-->
    <video style="max-width:100%; max-height:calc(100vh - 140px); object-fit: contain; cursor: pointer;"
          :src="video"
          ref="vid"
          type="video/mp4"
          @click="togglePlayback"
          @ended="videoended" />
    <div style="width:100%; height:70px; display:flex; justify-content:center; margin-bottom:140px; cursor: pointer;">
        <img style="height: 70px;"  v-if="playing" src="@/assets/ui/UI_video PAUSE button.png" @click="pause" />
        <img style="height: 70px;"  v-else src="@/assets/ui/UI_video PLAY button.png" @click="play" />
    </div>
  </div>

</template>

<script>
export default {
  name: 'ArtScene',
  props: {
      video: String
  },
  data(){
    return {
      playing:true,
      volume: 1.0,
      hasStarted:false
    }
  },
  methods: {
    volumeDown(){
      this.volume -= 0.1
      if(this.volume < 0) this.volume = 0

      this.$refs.vid.volume = this.volume
    },
    volumeUp(){
      this.volume += 0.1
      if(this.volume > 1) this.volume = 1

      this.$refs.vid.volume = this.volume
    },
    videoloaded(){
      this.$refs.vid.play()
      this.playing = true
    },
    videoended(){
      this.isPlaying = false;
      this.$emit('go-to-menu');
    },
    pause(){
      this.$refs.vid.pause()
      this.playing = false
    },
    play(){
      this.$refs.vid.play()
      this.playing = true
      this.hasStarted = true
    },
    stop(){
      this.$refs.vid.pause()
      this.$refs.vid.currentTime = 0;
    },
    togglePlayback(){
      if(this.playing){
        this.pause();
      }else{
        this.play();
      }
    }
  },
  mounted(){

  }
}
</script>

<style scoped>

</style>