<template>
    <div class="blue" style="max-width:920px; margin:auto; height:100%">

        <div style="z-index:50; position:fixed; left:0; top:0; width:100%; display:flex; justify-content:space-between; align-items:center;">
            <div style="width:33.333%; display:flex; justify-content:flex-start;">
                <img v-if="state !== 'MENU'" style="height: 70px; cursor: pointer;" :style="[ state === 'ABOUT_SCENE' ? {'background-color': 'rgb(47,182,255)'} : {'background-color': 'none'}]" src="@/assets/ui/UI_back arrow.png" @click="goToMenu" />
                <img v-if="state === 'MENU'" style="height: 70px; margin:20px; cursor: pointer;" src="@/assets/logo.png" />
            </div>
            <div style="width:33.333%;"></div>
        </div>
        <AboutScene v-if="state === 'ABOUT_SCENE'" @go-to-menu="goToMenu" />
        <VideoScene ref="art" v-show="currentVideo === artVideo"
                    :video="artVideo"
                    @go-to-menu="goToMenu" />
        <VideoScene ref="lawrence" v-show="currentVideo === videos[0]"
                    :video="videos[0]"
                    @go-to-menu="goToMenu" />
        <VideoScene ref="helen" v-show="currentVideo === videos[1]"
                    :video="videos[1]"
                    @go-to-menu="goToMenu" />
        <VideoScene ref="oscar" v-show="currentVideo === videos[2]"
                    :video="videos[2]"
                    @go-to-menu="goToMenu" />
        <VideoScene ref="tai" v-show="currentVideo === videos[3]"
                    :video="videos[3]"
                    @go-to-menu="goToMenu" />
        <div v-show="showCanvas" style="width:100vw; height:100vh; max-width:920px; margin:auto; display:flex; align-items:center; justify-content:center">
            <canvas
                id="canvas"
                width="2048"
                height="1536"
                style="width:100%; height:auto; max-width:920px;"
                @mousemove="mousemove"
                @click="click">
            </canvas>
        </div>
    </div>
</template>

<script>
import anime from 'animejs/lib/anime.es.js';
import AboutScene from '@/scenes/AboutScene'
import VideoScene from '@/scenes/VideoScene'
import noise from 'simplenoise'

//require('@/assets/fonts/AkkMo___.ttf')

let states = {
    MENU: "MENU",
    //to, scene, from
    ARTISTS_MENU_ANIMATE_TO: "ARTISTS_MENU_ANIMATE_TO",
    ARTISTS_MENU: "ARTISTS_MENU",
    ARTIST_SCENE: "ARTIST_SCENE",
    ARTISTS_MENU_ANIMATE_FROM: "ARTISTS_MENU_ANIMATE_FROM",

    //to, scene, from
    ART_SCENE_ANIMATE_TO: "ART_SCENE_ANIMATE_TO",
    ART_SCENE: "ART_SCENE",
    ART_SCENE_ANIMATE_FROM: "ART_SCENE_ANIMATE_FROM",

    //to, scene, from
    ABOUT_SCENE_ANIMATE_TO: "ABOUT_SCENE_ANIMATE_TO",
    ABOUT_SCENE: "ABOUT_SCENE",
    ABOUT_SCENE_ANIMATE_FROM: "ABOUT_SCENE_ANIMATE_FROM"
}

export default {
name: 'HomeScene',
components: {
    AboutScene,
    VideoScene
},
data(){
    return{
        state: states.MENU,
        artVideo: 'https://turnerprize.bryl.studio/media/JMWTurner.mp4',
        videos: [
            'https://turnerprize.bryl.studio/media/Lawrence.mp4',
            'https://turnerprize.bryl.studio/media/Helen.mp4',
            'https://turnerprize.bryl.studio/media/Oscar.mp4',
            'https://turnerprize.bryl.studio/media/Tai.mp4'],
        currentVideo: null,
        canvasWidth: 2048,
        canvasHeight: 1536,
        canvas: null,
        context: null,
        polyHit: -1,
        currentScene: -1,
        targetScene: -1,
        animDuration: 500,
        animCurve: 'easeInOutSine',
        animations: [],
        playIcon: null,
        artImage: {
            image: null,
            pos: [{x:0,y:0},{x:0,y:0}],
            menuPos: [
                {
                x: 279,
                y: 365
                },
                {
                x: 520,
                y: 293
                }
            ],
            menu2Pos: [
                {
                    x: 0,
                    y: 150
                },
                {
                    x: 2048,
                    y: 1152
                }
            ],
        },
        lawrenceImage: {
            image: null,
            pos: [{x:0,y:0},{x:0,y:0}],
            menuPos: [{x: 322,y: 899},{x: 302,y: 169}],
            menu2Pos: [{x: 344,y: 471},{x: 520,y: 293}]
        },
        helenImage: {
            image: null,
            pos: [{x:0,y:0},{x:0,y:0}],
            menuPos: [
                {
                x: 686,
                y: 943
                },
                {
                    x: 302,
                    y: 169
                }
            ],
            menu2Pos: [
                {
                x: 1160,
                y: 473
                },
                {
                    x: 520,
                    y: 293
                }
            ]
        },
        oscarImage: {
            image: null,
            pos: [{x:0,y:0},{x:0,y:0}],
            menuPos: [
                {
                x: 1147,
                y: 959
                },
                {
                    x: 302,
                    y: 169
                }
            ],
            menu2Pos: [
                {
                x: 376,
                y: 894
                },
                {
                    x: 520,
                    y: 293
                }
            ]
        },
        taiImage: {
            image: null,
            pos: [{x:0,y:0},{x:0,y:0}],
            menuPos: [
                {
                x: 1501,
                y: 753
                },
                {
                    x: 302,
                    y: 169
                }
            ],
            menu2Pos: [
                {
                x: 1255,
                y: 908
                },
                {
                    x: 520,
                    y: 293
                }
            ]
        },
        touchPolys: [
        {
            points: [
                {
                    x: 183,
                    y: 40
                },
                {
                    x: 298,
                    y: 71
                },
                {
                    x: 841,
                    y: 71
                },
                {
                    x: 859,
                    y: 243
                },
                {
                    x: 152,
                    y: 292
                }
            ]
        },
        {
            points: [
                {
                    x: 140,
                    y: 343
                },
                {
                    x: 236,
                    y: 343
                },
                {
                    x: 889,
                    y: 322
                },
                {
                    x: 830,
                    y: 557
                },
                {
                    x: 140,
                    y: 515
                }
            ]
        },
        {
            points: [
                {
                    x: 151,
                    y: 660
                },
                {
                    x: 212,
                    y: 625
                },
                {
                    x: 859,
                    y: 595
                },
                {
                    x: 869,
                    y: 705
                },
                {
                    x: 172,
                    y: 725
                }
            ]
        }
        ],
        polygons: [],
        mousePos: {x:0,y:0},
        bgColor: {
            r: 255,
            g: 255,
            b: 255
        },
        actualCanvasWidth: 0,
        frame: 0,
        noiseVec: [
            {
                x: -4,
                y: 0
            },
            {
                x: 5,
                y: 2
            },
            {
                x: 2,
                y: -7
            },
            {
                x: 8,
                y: 2
            },
            {
                x: -3,
                y: -5
            }
        ]
      }
  },
  computed: {
      showCanvas(){
          if(this.state === states.ABOUT_SCENE || this.state === states.ARTIST_SCENE || this.state === states.ART_SCENE){
              return false
          }
          return true
      }
  },
  mounted() {
    this.loadData()
    this.touchPolys.forEach(poly => {
        poly.points.forEach(point => {
            point.x *= 2;
            point.y *= 2;
        })
    })
    noise.seed(Math.random())

    this.loadImages()
    this.canvas = document.getElementById("canvas");
    this.canvas.width = this.canvasWidth;
    this.canvas.height = this.canvasHeight;
    this.context = this.canvas.getContext("2d");
    this.startAnimating(30);


  },
  methods: {
    click(e){

        let pos = this.getPosition(e, this.context);
        this.mousePos = pos

        if(this.state == states.ARTISTS_MENU){
            this.checkButtonHit(pos)
            return
        }

        if(this.state !== states.MENU){
            return
        }

        let self = this
        this.polyHit = this.hitTest(pos)
        this.targetScene = this.polyHit

        if(this.polyHit > -1){
            this.animations = []
            if(this.polyHit == 0){
                self.$refs.art.play()
                this.state = states.ART_SCENE_ANIMATE_TO
                this.animateToArtScene();
                /*setTimeout(function(){
                    self.$refs.art.play()
                }, self.animDuration)*/
            }
            else if(this.polyHit == 1){
                this.state = states.ARTISTS_MENU_ANIMATE_TO
                this.animateToArtistsMenu();
            }
            else if(this.polyHit == 2){
                this.state = states.ABOUT_SCENE_ANIMATE_TO
                this.animateToAboutScene();
            }


        }
    },
    mousemove(e){
        let pos = this.getPosition(e, this.context);
        this.mousePos = pos
    },
    checkpointer(){
        let pointer = false

        if(this.state == states.MENU){

            this.polyHit = this.hitTest(this.mousePos)
            if(this.polyHit > -1){
                pointer = true
            }

        }else if(this.state == states.ARTISTS_MENU){
            let images = [this.lawrenceImage, this.helenImage, this.oscarImage, this.taiImage]
            images.forEach((image) => {
                if(this.mousePos.x > image.menu2Pos[0].x && this.mousePos.x < image.menu2Pos[0].x + image.menu2Pos[1].x){
                    if(this.mousePos.y > image.menu2Pos[0].y && this.mousePos.y < image.menu2Pos[0].y + image.menu2Pos[1].y){
                        pointer = true
                    }
                }
            })
        }


        if(pointer){
            if(document.getElementById("app").style.cursor != 'pointer'){
                document.getElementById("app").style.cursor = 'pointer'
            }
        }else{
            if(document.getElementById("app").style.cursor != 'default'){
                document.getElementById("app").style.cursor = 'default'
            }

        }
    },
    loadData(){
        let data = require("../assets/data.json");
        data.poly1.pos = JSON.parse(JSON.stringify(data.poly1.menuPos))
        data.poly2.pos = JSON.parse(JSON.stringify(data.poly2.menuPos))
        data.poly3.pos = JSON.parse(JSON.stringify(data.poly3.menuPos))
        this.polygons.push(data.poly1)
        this.polygons.push(data.poly2)
        this.polygons.push(data.poly3)
    },
    loadImages(){
        this.artImage.image = new Image()
        this.artImage.image.src = require('@/assets/thumbnails/JMWTurner_Libarys_Present_Subtitled.png');
        this.artImage.pos = JSON.parse(JSON.stringify(this.artImage.menuPos))

        this.lawrenceImage.image = new Image()
        this.lawrenceImage.image.src = require('@/assets/thumbnails/Lawrence Abu Hamdan - Turner Prize SUBTITLED V3.png');
        this.lawrenceImage.pos = JSON.parse(JSON.stringify(this.lawrenceImage.menuPos))

        this.helenImage.image = new Image()
        this.helenImage.image.src = require('@/assets/thumbnails/Helen Cammock - Turner Prize SUBTITLED V2.png');
        this.helenImage.pos = JSON.parse(JSON.stringify(this.helenImage.menuPos))
        this.oscarImage.image = new Image()
        this.oscarImage.image.src = require('@/assets/thumbnails/Oscar Murillo - Turner Prize SUBTITLED V2.png');
        this.oscarImage.pos = JSON.parse(JSON.stringify(this.oscarImage.menuPos))
        this.taiImage.image = new Image()
        this.taiImage.image.src = require('@//assets/thumbnails/Tai Shani - Turner Prize SUBTITLED V2.png');
        this.taiImage.pos = JSON.parse(JSON.stringify(this.taiImage.menuPos))
        this.playIcon = new Image()
        this.playIcon.src = require('@//assets/ui/UI_video-thumb-play-(useat50opacity).png');
    },
    drawMenuText(){
        this.context.fillStyle = '#0075d5';
        this.context.textAlign = "center";
        this.context.font = "64px Akkurat";
        let x = this.canvasWidth/2;
        this.context.fillText("Turner Prize in Kent", x, 260);

        this.context.font = "42px Akkurat";
        this.context.fillText("Watch the film", x, 320);

        this.context.font = "64px Akkurat";
        this.context.fillText("Watch the films on", x, 780);
        this.context.fillText("the 2019 artists", x, 850);
        this.context.fillText("About Turner Prize", x, 1350);
    },
    drawArtistMenuText(){
        this.context.fillStyle = '#0075d5';
        this.context.textAlign = "center";
        this.context.font = "64px Akkurat";
        let x = this.canvasWidth/2;
        this.context.fillText("Turner Prize 2019 Artists", x, 300);
    },
    drawArtImage(){
        this.context.drawImage(this.artImage.image, this.artImage.pos[0].x, this.artImage.pos[0].y, this.artImage.pos[1].x, this.artImage.pos[1].y);
    },
    drawArtistImages(){
        this.context.drawImage(this.lawrenceImage.image, this.lawrenceImage.pos[0].x, this.lawrenceImage.pos[0].y, this.lawrenceImage.pos[1].x, this.lawrenceImage.pos[1].y);
        this.context.drawImage(this.helenImage.image, this.helenImage.pos[0].x, this.helenImage.pos[0].y, this.helenImage.pos[1].x, this.helenImage.pos[1].y);
        this.context.drawImage(this.oscarImage.image, this.oscarImage.pos[0].x, this.oscarImage.pos[0].y, this.oscarImage.pos[1].x, this.oscarImage.pos[1].y);
        this.context.drawImage(this.taiImage.image, this.taiImage.pos[0].x, this.taiImage.pos[0].y, this.taiImage.pos[1].x, this.taiImage.pos[1].y);
    },
    drawArtistNames(){
        this.context.font = "42px Akkurat";
        this.context.fillText("Lawrence Abu Hamdan", 480, 440);
        this.context.fillText("Helen Cammock", 1385,445);
        this.context.fillText("Oscar Murillo", 472,1230);
        this.context.fillText("Tai Shani", 1530,1250);
    },
    drawPlayIcons(){
        this.context.globalAlpha = 0.8;
        this.context.drawImage(this.playIcon, this.lawrenceImage.menu2Pos[0].x + 150, this.lawrenceImage.menu2Pos[0].y + 50, 200, 200);
        this.context.drawImage(this.playIcon, this.helenImage.menu2Pos[0].x + 150, this.helenImage.menu2Pos[0].y + 50, 200, 200);
        this.context.drawImage(this.playIcon, this.oscarImage.menu2Pos[0].x + 150, this.oscarImage.menu2Pos[0].y + 50, 200, 200);
        this.context.drawImage(this.playIcon, this.taiImage.menu2Pos[0].x + 150, this.taiImage.menu2Pos[0].y + 50, 200, 200);
        this.context.globalAlpha = 1.0;
    },
    goToMenu(){
        this.currentVideo = null

        this.$refs.art.stop()
        this.$refs.lawrence.stop()
        this.$refs.helen.stop()
        this.$refs.oscar.stop()
        this.$refs.tai.stop()

       // let self = this
        console.log('goToMenu', this.state)
        if(this.state == states.ARTISTS_MENU){
            this.state = states.ARTISTS_MENU_ANIMATE_FROM
            this.animateFromArtistsMenu()
        }else if(this.state == states.ART_SCENE){
            this.currentVideo = null
            this.state = states.ART_SCENE_ANIMATE_FROM
            this.animateFromArtScene()
        }else if(this.state == states.ARTIST_SCENE){
            this.currentVideo = null
            this.state = states.ARTISTS_MENU
        }else if(this.state == states.ABOUT_SCENE){
            this.state = states.ABOUT_SCENE_ANIMATE_FROM
            this.animateFromAboutScene()
        }

        /*this.animations.forEach((animation) => {
            animation.reverse()
            animation.play()
        })

        this.animations[0].complete = function(){
            if(self.state == states.ARTISTS_MENU_ANIMATE_FROM){
                self.state = states.MENU
            }else if(self.state == states.ART_SCENE_ANIMATE_FROM){
                self.state = states.MENU
            }
        }*/

    },
    goToScene(scene){

        if(this.targetScene === -1){
            this.polyHit = -1
        }

        this.targetScene = scene

        console.log('gotoscene', scene)
        this.currentScene = scene
    },
    startAnimating: function(fps) {
      this.fpsInterval = 1000 / fps;
      this.prevTime = Date.now();
      this.animate();
    },
    animate: function() {
      // request another frame
      requestAnimationFrame(this.animate);

      // calc elapsed time since last loop
      let now = Date.now();
      let elapsed = now - this.prevTime;

      // if enough time has elapsed, draw the next frame
      if (elapsed > this.fpsInterval) {
        // Get ready for next frame by setting then=now, but also adjust for your
        // specified fpsInterval not being a multiple of RAF's interval (16.7ms)
        this.prevTime = now - (elapsed % this.fpsInterval);

        this.draw();
      }
    },
    draw: function() {
      let self = this;
    this.checkpointer()
      //Clear the canvas
      this.context.clearRect(0,0,this.context.canvas.width,this.context.canvas.height);
      this.context.strokeStyle = 'rgb(47,182,255)';
      this.context.fillStyle = 'rgb(' + this.bgColor.r + ',' + this.bgColor.g+ ',' + this.bgColor.b + ')';
      this.context.fillRect(0,0,this.canvasWidth,this.canvasHeight);


        if(this.state == states.MENU){
            this.drawMenuText()
            this.drawArtImage();
            this.drawArtistImages()
        }else if(this.state == states.ART_SCENE_ANIMATE_TO){
            this.drawArtImage()
        }else if(this.state == states.ART_SCENE){
            this.drawArtImage()
        }else if(this.state == states.ART_SCENE_ANIMATE_FROM){
            this.drawArtImage()
        }else if (this.state == states.ARTISTS_MENU_ANIMATE_TO){
            this.drawArtistMenuText()
            this.drawArtistImages()
        }else if (this.state == states.ARTISTS_MENU){
            this.drawArtistMenuText()
            this.drawArtistImages()
            this.drawPlayIcons()
            this.drawArtistNames()
        }else if(this.state == states.ARTISTS_MENU_ANIMATE_FROM){
            this.drawArtistMenuText()
            this.drawArtistImages()
        }


        if(this.state == states.MENU){
            this.polygons.forEach((poly) => {
                this.context.save();
                self.context.beginPath();

                self.context.moveTo(poly.pos[0].x, poly.pos[0].y);
                poly.pos.forEach((point) => {
                    self.context.lineTo(point.x, point.y);
                })

                self.context.closePath();
                self.context.stroke();

                self.context.clip();
                this.context.fillStyle = 'rgb(47,182,255)';
                this.context.fillRect(0,0,this.canvasWidth,this.canvasHeight);

                this.context.restore();

            })

        }else if(
            this.state == states.ART_SCENE_ANIMATE_TO ||
            this.state == states.ART_SCENE ||
            this.state == states.ART_SCENE_ANIMATE_FROM){

                let poly = this.polygons[0]
                this.context.save();
                self.context.beginPath();

                self.context.moveTo(poly.pos[0].x, poly.pos[0].y);
                poly.pos.forEach((point) => {
                    self.context.lineTo(point.x, point.y);
                })

                self.context.closePath();
                self.context.stroke();

                self.context.clip();
                this.context.fillStyle = 'rgb(47,182,255)';
                this.context.fillRect(0,0,this.canvasWidth,this.canvasHeight);

                this.context.restore();

        }else if(
            this.state == states.ARTISTS_MENU_ANIMATE_TO ||
            this.state == states.ARTISTS_MENU ||
            this.state == states.ARTISTS_MENU_ANIMATE_FROM){

                let poly = this.polygons[1]
                this.context.save();
                self.context.beginPath();

                self.context.moveTo(poly.pos[0].x, poly.pos[0].y);
                poly.pos.forEach((point) => {
                    self.context.lineTo(point.x, point.y);
                })

                self.context.closePath();
                self.context.stroke();

                self.context.clip();
                this.context.fillStyle = 'rgb(47,182,255)';
                this.context.fillRect(0,0,this.canvasWidth,this.canvasHeight);

                this.context.restore();

        }else if(
            this.state == states.ABOUT_SCENE_ANIMATE_TO ||
            this.state == states.ABOUT_SCENE ||
            this.state == states.ABOUT_SCENE_ANIMATE_FROM){

                let poly = this.polygons[2]
                this.context.save();
                self.context.beginPath();

                self.context.moveTo(poly.pos[0].x, poly.pos[0].y);
                poly.pos.forEach((point) => {
                    self.context.lineTo(point.x, point.y);
                })

                self.context.closePath();
                self.context.stroke();

                self.context.clip();
                this.context.fillStyle = 'rgb(47,182,255)';
                this.context.fillRect(0,0,this.canvasWidth,this.canvasHeight);

                this.context.restore();
        }

    },
    hitTest(pos) {
        let i = -1;
        let self = this;
        this.touchPolys.forEach(function(poly, index) {

            if (self.insidePolygon(poly, pos)) {
                i = index;
            }
        });
        return i;
    },
    insidePolygon(poly, pos) {
        let x = pos.x;
        let y = pos.y;

        let inside = false;
        for (
        let i = 0, j = poly.points.length - 1;
        i < poly.points.length;
        j = i++
        ) {
        let xi = poly.points[i].x,
            yi = poly.points[i].y;
        let xj = poly.points[j].x,
            yj = poly.points[j].y;
        let intersect =
            yi > y != yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
        if (intersect) inside = !inside;
        }

        return inside;
    },
    getPosition(e, context) {
        let rect = context.canvas.getBoundingClientRect();

        let x = this.mapRange(e.clientX - rect.left, 0, rect.width, 0, this.canvasWidth);
        let y = this.mapRange(e.clientY - rect.top, 0, rect.height, 0, this.canvasHeight);
        return { x: x, y: y };
    },
    mapRange(num, in_min, in_max, out_min, out_max) {
        return ((num - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min;
    },
    checkButtonHit(pos){

        let images = [this.lawrenceImage, this.helenImage, this.oscarImage, this.taiImage]
        images.forEach((image,index) => {
            if(pos.x > image.menu2Pos[0].x && pos.x < image.menu2Pos[0].x + image.menu2Pos[1].x){
                if(pos.y > image.menu2Pos[0].y && pos.y < image.menu2Pos[0].y + image.menu2Pos[1].y){
                    this.currentVideo = this.videos[index]
                    this.state = states.ARTIST_SCENE
                    if(index === 0){
                        this.$refs.lawrence.play()
                    }else if(index === 1){
                        this.$refs.helen.play()
                    }else if(index === 2){
                        this.$refs.oscar.play()
                    }else if(index === 3){
                        this.$refs.tai.play()
                    }

                    return
                }
            }
        })

    },
    touchstart(){
        /*ife.preventDefault()
        this.currentVideo = this.artVideo
        this.$refs.vidscene.play()*/
       /* var touch = e.touches[0];
        this.touchPos = {
            clientX: touch.clientX,
            clientY: touch.clientY
        };
        var mouseEvent = new MouseEvent("mousedown", this.touchPos);
        this.canvas.dispatchEvent(mouseEvent);*/
    },
    getTouchPos(canvasDom, touchEvent) {
      var rect = canvasDom.getBoundingClientRect();
      return {
        x: touchEvent.touches[0].clientX - rect.left,
        y: touchEvent.touches[0].clientY - rect.top
      };
    },
    animateToAboutScene(){
        let self = this
        for(let i=0; i<5; i++){
            this.polygons[2].pos[i] = this.polygons[2].menu2Pos[i]
        }

        for(let i=5; i<this.polygons[2].pos.length; i++){
            this.animations.push(anime({
                targets: [this.polygons[2].pos[i]],
                x: this.polygons[2].menu2Pos[i].x,
                y: this.polygons[2].menu2Pos[i].y,
                duration: this.animDuration,
                round: 1,
                easing: this.animCurve,
                complete: function() {
                    if(i === 5){
                        self.state = states.ABOUT_SCENE
                    }

                }
            }))
        }

        this.animations.push(anime({
                targets: [this.bgColor],
                r: 47,
                g: 182,
                b: 255,
                duration: this.animDuration,
                round: 1,
                easing: this.animCurve
            }))


    },
    animateFromAboutScene(){
        this.animations = []
        console.log('animateFromAboutScene')
        let self = this
        for(let i=5; i<this.polygons[2].pos.length; i++){
            this.animations.push(anime({
                targets: [this.polygons[2].pos[i]],
                x: this.polygons[2].menuPos[i].x,
                y: this.polygons[2].menuPos[i].y,
                duration: this.animDuration,
                round: 1,
                easing: this.animCurve,
                complete: function(){
                    if(i === 5){
                        console.log('complete')
                        for(let i=0; i<5; i++){
                            self.polygons[2].pos[i] = self.polygons[2].menuPos[i]
                        }
                        self.state = states.MENU
                    }
                }
            }))
        }

        this.animations.push(anime({
                targets: [this.bgColor],
                r: 255,
                g: 255,
                b: 255,
                duration: this.animDuration,
                round: 1,
                easing: this.animCurve
            }))
    },
    animateToArtScene(){
        this.animations = []
        let self = this
        let polygon = this.polygons[0]
        for(let i=0; i<5; i++){
            polygon.pos[i] = polygon.menu2Pos[i]
        }

        for(let i=5; i<polygon.pos.length; i++){
            this.animations.push(anime({
                targets: [polygon.pos[i]],
                x: polygon.menu2Pos[i].x,
                y: polygon.menu2Pos[i].y,
                duration: this.animDuration,
                easing: this.animCurve,
                complete: function() {
                    if(i === 5){
                        self.currentVideo = self.artVideo
                        self.state = states.ART_SCENE
                    }

                }
            }))
        }

        this.animations.push(anime({
            targets: [this.artImage.pos[0]],
            x: this.artImage.menu2Pos[0].x,
            y: this.artImage.menu2Pos[0].y,
            duration: this.animDuration,
            round: 1,
            easing: this.animCurve
        }))

        this.animations.push(anime({
            targets: [this.artImage.pos[1]],
            x: this.artImage.menu2Pos[1].x,
            y: this.artImage.menu2Pos[1].y,
            duration: this.animDuration,
            round: 1,
            easing: this.animCurve
        }))

        this.animations.push(anime({
                targets: [this.bgColor],
                r: 47,
                g: 182,
                b: 255,
                duration: this.animDuration,
                round: 1,
                easing: this.animCurve
            }))
    },
    animateFromArtScene(){
        this.animations = []
        console.log('animateFromArtScene')
        let self = this
        for(let i=5; i<this.polygons[0].pos.length; i++){
            this.animations.push(anime({
                targets: [this.polygons[0].pos[i]],
                x: this.polygons[0].menuPos[i].x,
                y: this.polygons[0].menuPos[i].y,
                duration: this.animDuration,
                easing: this.animCurve,
                complete: function(){
                    if(i === 5){
                        for(let i=0; i<5; i++){
                            self.polygons[0].pos[i] = self.polygons[0].menuPos[i]
                        }
                        self.state = states.MENU
                    }
                }
            }))
        }

        this.animations.push(anime({
            targets: [this.artImage.pos[0]],
            x: this.artImage.menuPos[0].x,
            y: this.artImage.menuPos[0].y,
            duration: this.animDuration,
            easing: this.animCurve
        }))

        this.animations.push(anime({
            targets: [this.artImage.pos[1]],
            x: this.artImage.menuPos[1].x,
            y: this.artImage.menuPos[1].y,
            duration: this.animDuration,
            easing: this.animCurve
        }))

        this.animations.push(anime({
            targets: [this.bgColor],
            r: 255,
            g: 255,
            b: 255,
            duration: this.animDuration,
            round: 1,
            easing: this.animCurve
        }))
    },
    animateToArtistsMenu(){
        this.animations = []
        for(let i=0; i<5; i++){
            this.polygons[1].pos[i] = this.polygons[1].menu2Pos[i]
        }
        for(let i=4; i<this.polygons[1].pos.length; i++){
            this.animations.push(anime({
                targets: [this.polygons[1].pos[i]],
                x: this.polygons[1].menu2Pos[i].x,
                y: this.polygons[1].menu2Pos[i].y,
                duration: this.animDuration,
                round: 1,
                easing: this.animCurve
            }))
        }

        let self = this
        // lawrence
        this.animations.push(anime({
            targets: [this.lawrenceImage.pos[0]],
            x: this.lawrenceImage.menu2Pos[0].x,
            y: this.lawrenceImage.menu2Pos[0].y,
            duration: this.animDuration,
            round: 1,
            easing: this.animCurve,
            complete: function(){
                self.state = states.ARTISTS_MENU
            }
        }))

        this.animations.push(anime({
            targets: [this.lawrenceImage.pos[1]],
            x: this.lawrenceImage.menu2Pos[1].x,
            y: this.lawrenceImage.menu2Pos[1].y,
            duration: this.animDuration,
            round: 1,
            easing: this.animCurve
        }))

        // helen
        this.animations.push(anime({
            targets: [this.helenImage.pos[0]],
            x: this.helenImage.menu2Pos[0].x,
            y: this.helenImage.menu2Pos[0].y,
            duration: this.animDuration,
            round: 1,
            easing: this.animCurve
        }))

        this.animations.push(anime({
            targets: [this.helenImage.pos[1]],
            x: this.helenImage.menu2Pos[1].x,
            y: this.helenImage.menu2Pos[1].y,
            duration: this.animDuration,
            round: 1,
            easing: this.animCurve
        }))

        // oscar
        this.animations.push(anime({
            targets: [this.oscarImage.pos[0]],
            x: this.oscarImage.menu2Pos[0].x,
            y: this.oscarImage.menu2Pos[0].y,
            duration: this.animDuration,
            round: 1,
            easing: this.animCurve
        }))

        this.animations.push(anime({
            targets: [this.oscarImage.pos[1]],
            x: this.oscarImage.menu2Pos[1].x,
            y: this.oscarImage.menu2Pos[1].y,
            duration: this.animDuration,
            round: 1,
            easing: this.animCurve
        }))

        // tai
        this.animations.push(anime({
            targets: [this.taiImage.pos[0]],
            x: this.taiImage.menu2Pos[0].x,
            y: this.taiImage.menu2Pos[0].y,
            duration: this.animDuration,
            round: 1,
            easing: this.animCurve
        }))

        this.animations.push(anime({
            targets: [this.taiImage.pos[1]],
            x: this.taiImage.menu2Pos[1].x,
            y: this.taiImage.menu2Pos[1].y,
            duration: this.animDuration,
            round: 1,
            easing: this.animCurve
        }))
    },
    animateFromArtistsMenu(){
        let self = this
        this.animations = []
        for(let i=5; i<this.polygons[1].pos.length; i++){
            this.animations.push(anime({
                targets: [this.polygons[1].pos[i]],
                x: this.polygons[1].menuPos[i].x,
                y: this.polygons[1].menuPos[i].y,
                duration: this.animDuration,
                easing: this.animCurve,
                complete: function(){
                    if(i === 5){
                        console.log('complete')
                        for(let i=0; i<5; i++){
                            self.polygons[1].pos[i] = self.polygons[1].menuPos[i]
                        }
                        self.state = states.MENU
                    }
                }
            }))
        }

        // lawrence
        this.animations.push(anime({
            targets: [this.lawrenceImage.pos[0]],
            x: this.lawrenceImage.menuPos[0].x,
            y: this.lawrenceImage.menuPos[0].y,
            duration: this.animDuration,
            round: 1,
            easing: this.animCurve
        }))

        this.animations.push(anime({
            targets: [this.lawrenceImage.pos[1]],
            x: this.lawrenceImage.menuPos[1].x,
            y: this.lawrenceImage.menuPos[1].y,
            duration: this.animDuration,
            round: 1,
            easing: this.animCurve
        }))

        // helen
        this.animations.push(anime({
            targets: [this.helenImage.pos[0]],
            x: this.helenImage.menuPos[0].x,
            y: this.helenImage.menuPos[0].y,
            duration: this.animDuration,
            round: 1,
            easing: this.animCurve
        }))

        this.animations.push(anime({
            targets: [this.helenImage.pos[1]],
            x: this.helenImage.menuPos[1].x,
            y: this.helenImage.menuPos[1].y,
            duration: this.animDuration,
            round: 1,
            easing: this.animCurve
        }))

        // oscar
        this.animations.push(anime({
            targets: [this.oscarImage.pos[0]],
            x: this.oscarImage.menuPos[0].x,
            y: this.oscarImage.menuPos[0].y,
            duration: this.animDuration,
            round: 1,
            easing: this.animCurve
        }))

        this.animations.push(anime({
            targets: [this.oscarImage.pos[1]],
            x: this.oscarImage.menuPos[1].x,
            y: this.oscarImage.menuPos[1].y,
            duration: this.animDuration,
            round: 1,
            easing: this.animCurve
        }))

        // tai
        this.animations.push(anime({
            targets: [this.taiImage.pos[0]],
            x: this.taiImage.menuPos[0].x,
            y: this.taiImage.menuPos[0].y,
            duration: this.animDuration,
            round: 1,
            easing: this.animCurve
        }))

        this.animations.push(anime({
            targets: [this.taiImage.pos[1]],
            x: this.taiImage.menuPos[1].x,
            y: this.taiImage.menuPos[1].y,
            duration: this.animDuration,
            round: 1,
            easing: this.animCurve
        }))
    },

  }
}
</script>

<style scoped>
.home {
    width: 100vw;
    height: 75vw;
    max-height: 100vh;
    max-width: 133.33vh; /* 16/9 = 1.778 */
    margin: auto;
    position: absolute;
    top:0;bottom:0; /* vertical center */
    left:0;right:0; /* horizontal center */
    border: 1px solid red;
}

/* If you want text inside of the container */
.text {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

</style>