<template>
  <div id="app" >
    <HomeScene/>
  </div>
</template>

<script>
import HomeScene from './scenes/HomeScene.vue'

export default {
  name: 'App',
  components: {
    HomeScene
  }
}
</script>
